<script>
  import { DpsLink } from "diplanung-style";
  import { mapStores } from "pinia";

  import AppPageHeaderDropdown from "@/components/AppPageHeaderDropdown.vue";
  import { getSystemParameterBinary } from "@/services/admin.js";
  import { getEnvironmentLinks } from "@/services/location.ts";
  import { useAppStore } from "@/stores/app.ts";
  import { useFundamentalStore } from "@/stores/fundamental.ts";

  export default {
    name: "ThePageHeader",
    components: {
      DpsLink,
      AppPageHeaderDropdown,
    },
    props: {
      userName: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        logoBinaryUrl: undefined,
      };
    },
    computed: {
      ...mapStores(useAppStore, useFundamentalStore),
      isNutzerResolved() {
        return this.appStore.resolved["/nutzer"];
      },
      isAdminPage() {
        return (this.$route?.fullPath?.includes("/admin") && this.hasAdminRights) || false;
      },
      environmentLinks() {
        return getEnvironmentLinks();
      },
      userInfos() {
        return this.appStore.resolved["/nutzer"] === true
          ? this.fundamentalStore.userSettings
          : undefined;
      },
      userRole() {
        const rollen = this.userInfos?.rollen ?? [];

        return rollen.length === 0
          ? "keine Rollen vorhanden"
          : rollen.reduce((joinedRollen, rolle) => joinedRollen + ", " + rolle);
      },
      zustaendigkeit() {
        return this.userInfos?.zustaendigkeit?.name;
      },
      hasAdminRights() {
        return this.fundamentalStore.checkUserPermission("ADMIN");
      },
      hasReportRights() {
        // fachliche Klärung initiieren, ob hier weitere Rechte erforderlich sind
        return this.fundamentalStore.checkUserPermission("REPORT");
      },
      logoValue() {
        return this.fundamentalStore.getSystemParameterByName("MANDANT_LOGO")?.wert;
      },
      logoMimeType() {
        let fileEnding = this.logoValue ? this.logoValue?.split(".").pop() : "";

        switch (fileEnding.toLowerCase()) {
          case "svg":
            return "image/svg+xml";
          case "png":
            return "image/png";
          case "jpeg":
          case "jpg":
            return "image/jpeg";
          default:
            return undefined;
        }
      },
      hasLogoData() {
        return !!(this.logoMimeType && this.logoBinaryUrl);
      },
    },
    watch: {
      logoValue: {
        handler() {
          this.getLogoBinaryUrl();
        },
      },
      isNutzerResolved: {
        async handler(value) {
          if (value) {
            await this.fundamentalStore.getSystemParameterValue("MANDANT_LOGO", false, false);
          }
        },
      },
    },
    async beforeMount() {
      if (this.isNutzerResolved) {
        await this.fundamentalStore.getSystemParameterValue("MANDANT_LOGO", false, false);
      }
    },
    methods: {
      getLogoBinaryUrl() {
        if (this.logoValue) {
          getSystemParameterBinary("MANDANT_LOGO")
            .then((response) => {
              // create new blob to change mime type
              const blob = new Blob([response.data], { type: this.logoMimeType });

              this.logoBinaryUrl = URL.createObjectURL(blob);
            })
            .catch((error) => {
              console.error("Logo konnte nicht geladen werden: ", error);
              this.logoBinaryUrl = undefined;
            });
        }
      },
    },
  };
</script>

<template>
  <header class="dpc-page-header">
    <router-link
      class="dpc-page-header__logo-link"
      title="DiPlanung Cockpit - Startseite"
      :to="isNutzerResolved ? '/' : '/unauthorized'"
    >
      <img
        class="dpc-page-header__logo-link-image"
        :src="hasLogoData ? logoBinaryUrl : '/assets/logo.svg'"
        alt="DiPlanung Cockpit Logo"
      />
      <span class="dpc-page-header__logo-link-label">DiPlanung</span>
    </router-link>

    <div class="dpc-page-header__navigation-wrapper">
      <AppPageHeaderDropdown
        id="pageHeaderNavigationDropdown"
        :button-label="isAdminPage ? 'Fachliche Leitstelle Administration' : 'Cockpit'"
        button-title="Weitere Optionen anzeigen"
      >
        <li v-if="hasAdminRights && !isAdminPage">
          <router-link class="dropdown-item" to="/admin">
            Fachliche Leitstelle Administration
          </router-link>
        </li>
        <li v-if="isAdminPage">
          <router-link class="dropdown-item" to="/"> Startseite </router-link>
        </li>
        <li v-if="hasReportRights">
          <router-link class="dropdown-item" to="/"> Reports erzeugen </router-link>
        </li>
        <li>
          <a
            class="dropdown-item"
            :href="'//' + environmentLinks.beteiligung"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span class="dps-icon dps-icon--external-link"></span>
            DiPlanBeteiligung
          </a>
        </li>
        <li>
          <a
            class="dropdown-item"
            :href="'//' + environmentLinks.portal"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span class="dps-icon dps-icon--external-link"></span>
            DiPlanPortal
          </a>
        </li>
      </AppPageHeaderDropdown>
    </div>

    <nav class="dpc-page-header__nav">
      <DpsLink href="/ueber" title="Über DiPlanCockpit"> Über DiPlanCockpit </DpsLink>
    </nav>

    <div class="dpc-page-header__user-wrapper">
      <template v-if="userName && isNutzerResolved">
        <AppPageHeaderDropdown
          id="pageHeaderUserDropdown"
          second-icon="profil"
          button-title="Nutzerinformationen anzeigen"
        >
          <li>
            <a class="dropdown-item" :href="'/'"> {{ userName }} </a>
          </li>
          <li>
            <p class="dpc-page-header__user-wrapper-role">{{ userRole }}</p>
          </li>
          <li v-if="zustaendigkeit">
            <p class="dpc-page-header__user-wrapper-management">
              {{ zustaendigkeit }}
            </p>
          </li>
          <li><hr class="dropdown-divider" /></li>
          <li>
            <router-link class="dropdown-item" to="/logout">
              <span class="dps-icon dps-icon--logout"></span>
              Abmelden
            </router-link>
          </li>
        </AppPageHeaderDropdown>
      </template>
      <template v-else>
        <DpsLink href="/" title="Anmelden">Anmelden</DpsLink>
      </template>
    </div>
  </header>
</template>

<style lang="scss">
  @use "sass:map";
  @use "diplanung-style/src/scss/variables/link" as link-variables;
  @use "diplanung-style/src/scss/variables/typography" as typography-variables;
  @import "@/styles/imports";

  .dpc-page-header {
    @include navigation-spacing;

    display: flex;
    background: var(--dps-color-white);
    border-top: 6px solid var(--dps-color-primary-shade);
    min-height: 86px;
    align-items: center;
    color: var(--dps-color-black);
    font-size: 15px;
    position: sticky;
    top: 0;
    z-index: 1001;

    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 8px;
      background: linear-gradient(180deg, #f2f2f2 0%, rgb(242 242 242 / 0%) 100%);
    }

    &__logo-link {
      display: inline-flex;
      align-items: center;
      text-decoration: none;
      color: inherit;
      gap: 12px;
      font-weight: map.get(typography-variables.$font-weight-values, "semi-bold");
      font-size: inherit;
      transition: link-variables.$link-transition-settings;

      &:hover {
        color: var(--dps-color-primary);
      }
    }

    &__logo-link-image {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 39px;
    }

    &__navigation-wrapper {
      display: flex;
      align-items: center;
      font-weight: map.get(typography-variables.$font-weight-values, "semi-bold");
      margin-right: 24px;

      &::before {
        content: "";
        width: 1px;
        height: 22px;
        background: #949494;
        margin: 0 8px;
        display: inline-block;
      }
    }

    &__nav {
      margin-left: auto;
      display: inline-flex;
      align-items: center;
      gap: 22px;
      height: 100%;
      overflow-x: auto;
    }

    .dps-link {
      .dps-link__text {
        color: var(--dps-color-black);
        font-size: 15px;
        font-weight: 400;
        white-space: nowrap;
      }

      &:hover {
        .dps-link__text {
          text-decoration: none;
          color: var(--dps-color-primary);
        }
      }
    }

    &__user-wrapper {
      display: flex;
      align-items: center;

      .dpc-page-header__nav + & {
        margin-left: 24px;
      }

      &-role,
      &-management {
        color: var(--dps-color-dark-gray);
      }
    }
  }
</style>
