import type Keycloak from "keycloak-js";
import type { KeycloakTokenParsed } from "keycloak-js";
import { defineStore } from "pinia";

import keycloakService from "@/services/keycloak";

export interface AuthStoreState {
  authenticated: undefined | boolean;
  idTokenParsed: undefined | KeycloakTokenParsed;
  token: undefined | string;
  refreshTimer: number;
}

export const useAuthStore = defineStore("auth", {
  state: (): AuthStoreState => ({
    authenticated: false,
    idTokenParsed: undefined,
    token: undefined,
    refreshTimer: 0,
  }),

  actions: {
    // initialize keycloak
    async init() {
      if (!this.authenticated) {
        try {
          const keycloak = await keycloakService.CallInit(this.logout);

          await this.update(keycloak);
        } catch (error) {
          console.error(error);
        }
      }
    },
    // update authentication states
    async update(keycloak: Keycloak | undefined, clearData = true) {
      if (clearData) {
        await this.clearData();
      }

      if (keycloak) {
        this.authenticated = keycloak.authenticated;
        this.idTokenParsed = keycloak.idTokenParsed;
        this.token = keycloak.token;
      }
    },
    // Logout user
    async logout(redirect = true) {
      try {
        if (redirect) {
          await keycloakService.CallLogoutRedirect();
        } else {
          await keycloakService.CallLogout();
        }
        await this.clearData();
        this.stopRefreshTimer();
      } catch (error) {
        console.error(error);
      }
    },
    // stop timer to refresh token
    stopRefreshTimer() {
      if (this.refreshTimer !== 0) {
        clearInterval(this.refreshTimer);
        // reset refreshTimer
        this.refreshTimer = 0;
      }
    },
    // start timer to refresh token
    startRefreshTimer() {
      if (this.refreshTimer !== 0) {
        return;
      }

      const accessToken = this.idTokenParsed;

      if (accessToken && accessToken.exp) {
        const now = Math.floor(Date.now() / 1000);

        // set remainingTimeSeconds to current refresh rate (seconds)
        const remainingTimeSeconds = accessToken.exp - now;
        // set timer interval to refresh rate in milliseconds
        const interval = remainingTimeSeconds * 1000;

        this.refreshTimer = window.setInterval(async () => {
          try {
            await this.refreshUserToken(remainingTimeSeconds);
          } catch (err) {
            await this.logout();
          }
        }, interval);
      }
    },
    // refresh user token
    async refreshUserToken(tokenInterval: number) {
      try {
        const keycloak = await keycloakService.CallTokenRefresh(tokenInterval);

        await this.update(keycloak, false);
      } catch (error) {
        console.error(error);
      }
    },
    // Clear data
    clearData() {
      this.authenticated = false;
      this.token = undefined;
      this.idTokenParsed = undefined;
    },
  },
});
