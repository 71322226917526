import axios from "axios";
import { defineStore } from "pinia";

import router from "@/router/index.js";
import { getTraceIdString } from "@/services/app.js";
import { SystemVersionRest } from "@/services/open-api";
import { openAPIFactory } from "@/services/open-api.js";

export interface RouteMetaData {
  title?: string[];
  label?: string;
  isTasksView?: boolean;
  requiredPermissions?: string[] | { [key: string]: string[] };
  resolve?: {
    [key in ResolveTypes]: () => unknown;
  };
}

export interface IndicationModal {
  status?: "error" | "warning" | "info" | "success";
  info: string;
  buttontext: string;
  headline: string;
}

export interface PageLoadingIndicator {
  id: string;
  text: string;
}

export interface CockpitError {
  code?: string;
  message?: string;
  name?: string;
  response?: {
    status?: number;
    statusText?: string;
    data?: {
      message?: string;
      statusText?: string;
    };
  };
  data?: {
    message?: string;
    statusText?: string;
  };
}

export interface ErrorMessage {
  customErrorMessage?: string;
  response: CockpitError;
}

export interface RuntimeEnvironment {
  k1: {
    url: string;
  };
  masterportal: {
    url_erfassung: string;
    url_detail: string;
    url_planwerke: string;
  };
}

type ResolveTypes =
  | "/konfigurationen/blacklist"
  | "/konfigurationen/codelisten"
  | "/konfigurationen/systemparameter"
  | "/konfigurationen/verfahren"
  | "/nutzer"
  | "/nutzer/verfahren"
  | "/verfahren";

export interface AppStoreState {
  runtimeEnvironment: RuntimeEnvironment;
  pageLoadingIndicators: PageLoadingIndicator[];
  indicationModals: IndicationModal[];
  hasUserLoadingError: boolean;
  resolved: {
    [key in ResolveTypes]: boolean;
  };
  k1Version: SystemVersionRest;
  mapIsFullscreen: boolean;
}

export const useAppStore = defineStore("app", {
  state: (): AppStoreState => ({
    runtimeEnvironment: {
      k1: {
        url: "/api/cockpit/",
      },
      masterportal: {
        url_erfassung: "/masterportal/erfassung/",
        url_detail: "/masterportal/detail/",
        url_planwerke: "/masterportal/planwerke/",
      },
    },
    hasUserLoadingError: false,
    pageLoadingIndicators: [],
    indicationModals: [],
    resolved: {
      /**
       * liefert die Blackliste aus
       */
      "/konfigurationen/blacklist": false,
      /**
       * getAllCodelisten: Liefert alle Codelisten aus
       */
      "/konfigurationen/codelisten": false,
      /**
       * liefert die SystemParamter(alle) aus
       */
      "/konfigurationen/systemparameter": false,
      /**
       * getAll: Liefert(alle) VerfahrenKonfigurationen
       */
      "/konfigurationen/verfahren": false,
      /**
       * getNutzer: Nutzerinformationen
       */
      "/nutzer": false,
      /**
       * getNutzerVerfahren: Verfahren IDs der abonnierten Verfahren
       */
      "/nutzer/verfahren": false,
      /**
       * getAllVerfahren: Liefert die Metadaten zu allen Verfahren, die ich sehen darf (abonniert habe).
       */
      "/verfahren": false,
    },
    k1Version: {
      version: undefined,
      build: undefined,
      timestamp: undefined,
      branch: undefined,
      revision: undefined,
      xbauleitplanungVersion: undefined,
    },
    mapIsFullscreen: false,
  }),
  actions: {
    /**
     * Loads and caches infos about the runtime environment.
     */
    loadRuntimeEnvironment(): Promise<RuntimeEnvironment> {
      return new Promise((resolve, reject) => {
        axios
          .get("/config.json", {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((response) => {
            this.runtimeEnvironment = response.data as RuntimeEnvironment;

            resolve(response.data);
          })
          .catch(() => {
            console.error("Failed to load configuration file 'config.json'");
            alert("Could not load 'config.json'");

            reject();
          });
      });
    },
    /**
     * Loads information on the version of K1.
     */
    loadK1Version(): Promise<SystemVersionRest> {
      return new Promise((resolve, reject) => {
        openAPIFactory
          .versionResourceApiFactory()
          .getVersionInfo()
          .then((response) => {
            this.k1Version = response.data;

            resolve(response.data);
          })
          .catch((error) => {
            this.showErrorModal({
              response: error,
              customErrorMessage: "Abfrage der K1-Version fehlgeschlagen!",
            });

            reject(error);
          });
      });
    },
    /**
     * Shows an indication modal containing the composed error message.
     */
    async showErrorModal(payload: ErrorMessage) {
      let errorMessage = "Schwerwiegender Fehler aufgetreten.";

      const responseStatus = payload.response.response?.status;

      if (!this.resolved["/nutzer"]) {
        console.error(payload.customErrorMessage, " Ursache: ", payload.response.message);

        if (responseStatus !== undefined && responseStatus >= 500 && responseStatus < 600) {
          router.replace({ name: "ServerError" });
          return;
        }
      }

      if (responseStatus === 403) {
        router.replace({ name: "AppAccessDenied" });
        return;
      }

      if (typeof payload.response === "object") {
        let parsedResponse = undefined;

        // if response data is a blob turn it into JSON Object
        if (payload.response?.response?.data instanceof Blob) {
          await payload.response.response.data.text().then((value) => {
            parsedResponse = { response: { data: JSON.parse(value) } };
          });
        }

        if (!parsedResponse) {
          parsedResponse = payload.response;
        }

        if (parsedResponse?.response?.data?.message) {
          errorMessage = "Fehlermeldung: " + parsedResponse.response.data.message;
        } else if (parsedResponse.data?.message) {
          errorMessage = "Fehlermeldung: " + parsedResponse.data.message;
        } else if (parsedResponse.data?.statusText) {
          errorMessage = "Fehlermeldung: " + parsedResponse.data.statusText;
        } else if ("status" in parsedResponse && "statusText" in parsedResponse) {
          errorMessage =
            "Fehlercode: HTTP/" +
            parsedResponse.status +
            ", Fehlermeldung: " +
            parsedResponse.statusText;
        } else if ("message" in parsedResponse) {
          errorMessage = "Fehlerbeschreibung: " + parsedResponse.message;
        }
      }

      if (typeof payload.response === "object" && typeof payload.customErrorMessage === "string") {
        errorMessage =
          "<span class='error'>" + payload.customErrorMessage + "</span><br>" + errorMessage;
      }

      const traceIdString = getTraceIdString(payload.response || payload);

      this.showIndicationModal({
        status: "error",
        info: errorMessage + traceIdString,
        buttontext: "Schließen",
        headline: "Unerwarteter Fehler",
      });
    },
    /**
     * Shows an indication modal.
     * @param payload The modal's content. Must feature the properties "headline", "info", "status" and "buttontext".
     */
    showIndicationModal(payload: IndicationModal) {
      this.indicationModals = [...this.indicationModals, payload];
    },
    /**
     * Hides a specific indication modal.
     */
    hideIndicationModal() {
      this.indicationModals.pop();
    },
    /**
     * Shows a page loading indicator.
     * @param payload The definition of the page loading indicator to display.
     */
    showPageLoadingIndicator(payload: PageLoadingIndicator) {
      this.pageLoadingIndicators = [...this.pageLoadingIndicators, payload];
    },
    /**
     * Hides a specific page loading indicator.
     * @param id The ID of the page loading indicator that will be hidden.
     */
    hidePageLoadingIndicator(id: string) {
      this.pageLoadingIndicators = this.pageLoadingIndicators.filter((bar) => bar.id !== id);
    },
  },
  getters: {
    isResolvedRoute(): boolean {
      // compute loading status from meta-data for this page
      const routeMeta = router.currentRoute.value.meta as RouteMetaData;
      const resolve = routeMeta.resolve ?? {};

      return Object.keys(resolve).reduce(
        (result: boolean, value: string) => result && this.resolved[value as ResolveTypes],
        true,
      );
    },
  },
});
