/* tslint:disable */
/* eslint-disable */
/**
 * K1 OpenAPI
 * API fuer die Nutzung der K1 REST-Schnittstelle
 *
 * The version of the OpenAPI document: 1.131.0
 * Contact: diplan-backend@init.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ErrorRest } from '../models';
// @ts-ignore
import { RolleNeuRest } from '../models';
// @ts-ignore
import { RolleRest } from '../models';
/**
 * RolleResourceApi - axios parameter creator
 * @export
 */
export const RolleResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * <b>Benötigte Rechte:</b> ADMIN
         * @param {RolleNeuRest} rolleNeuRest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRolle: async (rolleNeuRest: RolleNeuRest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rolleNeuRest' is not null or undefined
            assertParamExists('createRolle', 'rolleNeuRest', rolleNeuRest)
            const localVarPath = `/konfigurationen/rollen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rolleNeuRest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> ADMIN
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRolle: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteRolle', 'id', id)
            const localVarPath = `/konfigurationen/rollen/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> ADMIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRollen: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/konfigurationen/rollen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> ADMIN
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRolle: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRolle', 'id', id)
            const localVarPath = `/konfigurationen/rollen/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> ADMIN
         * @param {RolleRest} rolleRest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRolle: async (rolleRest: RolleRest, id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rolleRest' is not null or undefined
            assertParamExists('updateRolle', 'rolleRest', rolleRest)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateRolle', 'id', id)
            const localVarPath = `/konfigurationen/rollen/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rolleRest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RolleResourceApi - functional programming interface
 * @export
 */
export const RolleResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RolleResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * <b>Benötigte Rechte:</b> ADMIN
         * @param {RolleNeuRest} rolleNeuRest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRolle(rolleNeuRest: RolleNeuRest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RolleRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRolle(rolleNeuRest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolleResourceApi.createRolle']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> ADMIN
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRolle(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRolle(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolleResourceApi.deleteRolle']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> ADMIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllRollen(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RolleRest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllRollen(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolleResourceApi.getAllRollen']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> ADMIN
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRolle(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RolleRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRolle(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolleResourceApi.getRolle']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> ADMIN
         * @param {RolleRest} rolleRest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRolle(rolleRest: RolleRest, id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RolleRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRolle(rolleRest, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RolleResourceApi.updateRolle']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RolleResourceApi - factory interface
 * @export
 */
export const RolleResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RolleResourceApiFp(configuration)
    return {
        /**
         * <b>Benötigte Rechte:</b> ADMIN
         * @param {RolleNeuRest} rolleNeuRest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRolle(rolleNeuRest: RolleNeuRest, options?: any): AxiosPromise<RolleRest> {
            return localVarFp.createRolle(rolleNeuRest, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> ADMIN
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRolle(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteRolle(id, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> ADMIN
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRollen(options?: any): AxiosPromise<Array<RolleRest>> {
            return localVarFp.getAllRollen(options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> ADMIN
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRolle(id: number, options?: any): AxiosPromise<RolleRest> {
            return localVarFp.getRolle(id, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> ADMIN
         * @param {RolleRest} rolleRest 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRolle(rolleRest: RolleRest, id: number, options?: any): AxiosPromise<RolleRest> {
            return localVarFp.updateRolle(rolleRest, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RolleResourceApi - object-oriented interface
 * @export
 * @class RolleResourceApi
 * @extends {BaseAPI}
 */
export class RolleResourceApi extends BaseAPI {
    /**
     * <b>Benötigte Rechte:</b> ADMIN
     * @param {RolleNeuRest} rolleNeuRest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolleResourceApi
     */
    public createRolle(rolleNeuRest: RolleNeuRest, options?: RawAxiosRequestConfig) {
        return RolleResourceApiFp(this.configuration).createRolle(rolleNeuRest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> ADMIN
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolleResourceApi
     */
    public deleteRolle(id: number, options?: RawAxiosRequestConfig) {
        return RolleResourceApiFp(this.configuration).deleteRolle(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> ADMIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolleResourceApi
     */
    public getAllRollen(options?: RawAxiosRequestConfig) {
        return RolleResourceApiFp(this.configuration).getAllRollen(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> ADMIN
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolleResourceApi
     */
    public getRolle(id: number, options?: RawAxiosRequestConfig) {
        return RolleResourceApiFp(this.configuration).getRolle(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> ADMIN
     * @param {RolleRest} rolleRest 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolleResourceApi
     */
    public updateRolle(rolleRest: RolleRest, id: number, options?: RawAxiosRequestConfig) {
        return RolleResourceApiFp(this.configuration).updateRolle(rolleRest, id, options).then((request) => request(this.axios, this.basePath));
    }
}

